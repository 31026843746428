import React from 'react';
import { Link } from 'gatsby';

import logo from '../img/logo.svg';
import facebook from '../img/social/facebook.svg';

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer">
        <div className="has-text-centered">
          <Link to="/" className="logo" title="Logo">
            <img src={logo} alt="Kaldi" width={400} />
          </Link>
        </div>
        <div className="has-text-centered">
          <div className="container">
            <div className="social">
              <a
                target="_blank"
                rel="noopener noreferrer"
                title="facebook"
                href="https://www.facebook.com/groups/194357744008279/"
              >
                <img src={facebook} alt="Facebook" style={{ width: '1em', height: '1em' }} />
              </a>
            </div>
            <div className="has-text-centered copyright">
              @{new Date().getFullYear()} New Hope Cumberland Presbyterian Church All Rights
              Researved. Developed by <a href="https://keisukeohtani.com/">Kei Ohtani</a>.
            </div>
          </div>
        </div>
      </footer>
    );
  }
};

export default Footer;
