import React from 'react';
import { Link } from 'gatsby';
import logo from '../img/logo.svg';

interface Props {}

const Navbar: React.FC<Props> = () => {
  const [active, setActive] = React.useState(false);
  const [navbarActiveClass, setNavbarActiveClassActive] = React.useState('');

  const toggleHamburger = () => {
    // toggle the active boolean in the state
    setActive(!active);
    setNavbarActiveClassActive(active ? 'is-active' : '');
  };

  return (
    <nav className="navbar is-transparent" role="navigation" aria-label="main-navigation">
      <div className="container is-fluid">
        <div className="navbar-brand navbar-start navbar-center">
          <Link to="/" className="navbar-item logo force-center" title="Logo">
            <img src={logo} alt="Logo" height={200} width={300} />
          </Link>
          <div
            role="button"
            aria-label="menu"
            aria-expanded="false"
            className={`navbar-burger burger ${navbarActiveClass}`}
            data-target="navMenu"
            onClick={toggleHamburger}
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </div>
        </div>
        <div id="navMenu" className={`navbar-menu ${navbarActiveClass}`}>
          <div className="navbar-start navbar-center has-text-centered">
            <div className="navbar-item">
              <Link to="/about">About</Link>
            </div>
            <div className="navbar-item">
              <Link to="/ministries">Ministries</Link>
            </div>
            <Link to="/" className="navbar-item logo force-center" title="Logo" id="logo">
              <img src={logo} alt="Logo" height={200} width={300} />
            </Link>
            <div className="navbar-item">
              <Link to="/announcement">Announcement</Link>
            </div>
            <div className="navbar-item">
              <Link to="/contact">Contact</Link>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
